export enum SortType {
  DATE = 'StartDate',
  DATE_DESC = 'StartDate desc',
  DATE_ASC = 'StartDate asc',
  CREATED_DATE = 'Job.CreatedAt',
  CREATED_DATE_ASC = 'Job.CreatedAt asc',
  CREATED_DATE_DESC = 'Job.CreatedAt desc',
  CLIENT_NAME_ASC = 'Name asc',
  NAME_ASC = 'Name FamilyName asc',
  NAME = 'Name FamilyName',
  NAME_DESC = 'Name FamilyName desc',
  JOB_POSITION = 'JobPosition',
  JOB_POSITEON_DESC = 'JobPosition desc',
  JOB_ID_DESC = 'JobId desc',
  JOB_ID_ASC = 'JobId asc',
  JOB_ID = 'JobId',
  ROLE_ASC = 'Role asc',
  ROLE = 'Role',
  TOTAL_EVENTS_ASC = 'TotalEvents asc',
  TOTAL_EVENTS_DESC = 'TotalEvents desc',
  EVENT_JOB_NUMBER = 'JobId ChildId',
  EVENT_JOB_NUMBER_ASC = 'JobId ChildId asc',
  EVENT_JOB_NUMBER_DESC = 'JobId desc,ChildId',
  EVENT_NAME = 'Name',
  EVENT_NAME_DESC = 'Name desc',
  EVENT_STAGE = 'Stage',
  EVENT_STAGE_DESC = 'Stage desc',
  EVENT_TYPE_ASC = 'Type asc',
  EVENT_TYPE_DESC = 'Type desc',
  BUDGET_TEMPLATE_NAME = 'Name',
  BUDGET_TEMPLATE_NAME_ASC = 'Name asc',
  BUDGET_TEMPLATE_NAME_DESC = 'Name desc'
}

import { TABLE_CONFIG } from 'config/constants';
import {
  BUDGET_TEMPLATE_URL,
  BUDGET_TEMPLATE_COMPONENTS_URL,
  BUDGET_TEMPLATE_CLONE_URL,
  BUDGET_TEMPLATE_ARCHIVE_URL,
  EVENT_BUDGET_URL,
  EVENT_BUDGET_URL_CONTRACT_LABOR_TYPES,
  EVENT_BUDGET_URL_SHIPPING_AND_POSTAGE_TYPES,
  EVENT_BUDGET_URL_CREATIVE_DEVELOPMENT_TYPES,
  EVENT_BUDGET_URL_SUPPORT_COLLATERALS_TYPES,
  EVENT_BUDGET_URL_AUDIO_VISUAL_TYPES,
  EVENT_BUDGET_URL_ADDITIONAL_COSTS_TYPES,
  EVENT_BUDGET_URL_DIRECT_MARKETING_TYPES,
  EVENT_BUDGET_URL_AUDIENCE_GENERATION_TYPES,
  EVENT_BUDGET_URL_OTHER_COST_TYPES,
  EVENT_BUDGET_URL_ACCOMMODATION_ROOM_TYPE_TYPES,
  EVENT_BUDGET_URL_SIGNAGE_TYPES,
  EVENT_BUDGET_URL_FOOD_TYPES,
  EVENT_WORKING_BUDGET_URL,
  EVENT_SIMPLE_RECON_URL,
  EVENT_RECONCILIATION_URL
} from 'config/endpoints';
import { fetchAPI } from 'services/http/fetch';
import { EMethods, IFetchOptions } from 'services/http/types';
import { SortType } from 'types/SortType';
import { getToken } from 'utils/authentication';
import {
  GenericComponenteType,
  IBudgetTemplate,
  IBudgetTemplateResponse,
  IComponentBudgetTemplateSegments
} from 'types/IBudget';
import { IEventReconciliation, IEventWorkingBudget, IEventSimpleRecon } from 'types/IEvent';

export interface IBudgetTemplateParams {
  PageNumber?: number;
  PageSize?: number;
  OrderBy?: string;
  Search?: string;
  Inactive?: string;
  userId: number | string;
}

const initialParams: IBudgetTemplateParams = {
  PageNumber: 0,
  PageSize: TABLE_CONFIG.ROWS_PER_PAGE_OPTIONS[0],
  userId: null,
  OrderBy: SortType.BUDGET_TEMPLATE_NAME_DESC
};

const optionsGetBudgetTemplates: IFetchOptions = {
  method: EMethods.GET,
  URL: BUDGET_TEMPLATE_URL,
  params: initialParams
};

const getBudgetTemplates = (params?: IBudgetTemplateParams): Promise<IBudgetTemplateResponse> => {
  if (params) {
    const newOptions: IFetchOptions = {
      ...optionsGetBudgetTemplates,
      params: {
        ...params,
        OrderBy: params.OrderBy
      }
    };
    return fetchAPI(newOptions);
  }
  return fetchAPI(optionsGetBudgetTemplates);
};

const optionsGetBudgetTemplateById: IFetchOptions = {
  method: EMethods.GET,
  URL: BUDGET_TEMPLATE_URL,
  params: initialParams
};

const getBudgetTemplateById = (templateId?: number | string): Promise<IBudgetTemplate> => {
  const newOptions: IFetchOptions = {
    ...optionsGetBudgetTemplateById,
    params: templateId,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsGetSegments: IFetchOptions = {
  method: EMethods.GET,
  URL: BUDGET_TEMPLATE_COMPONENTS_URL
};

const getComponents = (params?: any): Promise<IComponentBudgetTemplateSegments[]> => {
  if (params) {
    const newOptions: IFetchOptions = {
      ...optionsGetSegments,
      params
    };
    return fetchAPI(newOptions);
  }
  return fetchAPI(optionsGetSegments);
};

const optionsAddTemplate: IFetchOptions = {
  method: EMethods.POST,
  URL: BUDGET_TEMPLATE_URL
};

const addTemplate = (body): Promise<IBudgetTemplate> => {
  const newOptions: IFetchOptions = {
    ...optionsAddTemplate,
    body,
    headers: {
      authorization: `Bearer ${getToken()}`
    }
  };
  return fetchAPI(newOptions);
};

const optionsCloneBudgetTemplate: IFetchOptions = {
  method: EMethods.POST,
  URL: BUDGET_TEMPLATE_CLONE_URL
};

const cloneBudgetTemplate = (params): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsCloneBudgetTemplate,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsArchiveBudgetTemplate: IFetchOptions = {
  method: EMethods.DELETE,
  URL: BUDGET_TEMPLATE_ARCHIVE_URL
};

const archiveBudgetTemplate = (templateId: number | string): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsArchiveBudgetTemplate,
    params: templateId,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsUpdateBudgetTemplate: IFetchOptions = {
  method: EMethods.PUT,
  URL: BUDGET_TEMPLATE_URL
};

const updateBudgetTemplate = (budgetTemplate: IBudgetTemplate): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsUpdateBudgetTemplate,
    body: budgetTemplate
  };
  return fetchAPI(newOptions);
};

const optionsUpdateWorkingBudgetTemplate: IFetchOptions = {
  method: EMethods.PUT,
  URL: EVENT_WORKING_BUDGET_URL
};

const updateWorkingBudgetTemplate = (budgetTemplate: IEventWorkingBudget): Promise<IEventWorkingBudget> => {
  const newOptions: IFetchOptions = {
    ...optionsUpdateWorkingBudgetTemplate,
    body: budgetTemplate
  };
  return fetchAPI(newOptions);
};

const optionsUpdateSimpleReconTemplate: IFetchOptions = {
  method: EMethods.PUT,
  URL: EVENT_SIMPLE_RECON_URL
};

const updateSimpleReconTemplate = (budgetTemplate: IEventSimpleRecon): Promise<IEventSimpleRecon> => {
  const newOptions: IFetchOptions = {
    ...optionsUpdateSimpleReconTemplate,
    body: budgetTemplate
  };
  return fetchAPI(newOptions);
};

const optionsCreateSimpleReconTemplate: IFetchOptions = {
  method: EMethods.POST,
  URL: EVENT_SIMPLE_RECON_URL
};

const createSimpleReconTemplate = (budgetTemplate: IEventSimpleRecon): Promise<IEventSimpleRecon> => {
  const newOptions: IFetchOptions = {
    ...optionsCreateSimpleReconTemplate,
    body: budgetTemplate
  };
  return fetchAPI(newOptions);
};

const optionsUpdateEventReconciliation: IFetchOptions = {
  method: EMethods.PUT,
  URL: EVENT_RECONCILIATION_URL
};

const updateEventReconciliation = (reconciliation: IEventReconciliation): Promise<IEventReconciliation> => {
  const newOptions: IFetchOptions = {
    ...optionsUpdateEventReconciliation,
    body: reconciliation
  };
  return fetchAPI(newOptions);
};

const optionsApproveEventChanges: IFetchOptions = {
  method: EMethods.POST,
  URL: EVENT_RECONCILIATION_URL
};

const approveEventChanges = (eventId: number): Promise<IEventReconciliation> => {
  const newOptions: IFetchOptions = {
    ...optionsApproveEventChanges,
    params: {
      eventId
    }
  };
  return fetchAPI(newOptions);
};

const optionsAssignTemplateToEvent: IFetchOptions = {
  method: EMethods.POST,
  URL: EVENT_BUDGET_URL
};

const assignTemplateToEvent = (params): Promise<any> => {
  const newOptions: IFetchOptions = {
    ...optionsAssignTemplateToEvent,
    params,
    paramInUrlLine: true
  };
  return fetchAPI(newOptions);
};

const optionsGetContractLaborTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_CONTRACT_LABOR_TYPES
};

const optionsGetShippingAndPostage: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_SHIPPING_AND_POSTAGE_TYPES
};

const optionsGetCreativeDevelopment: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_CREATIVE_DEVELOPMENT_TYPES
};

const optionsGetSupportCollaterals: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_SUPPORT_COLLATERALS_TYPES
};

const optionsGetSignage: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_SIGNAGE_TYPES
};

const optionsGetAudioVisualTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_AUDIO_VISUAL_TYPES
};

const optionsGetAdditionalCostsTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_ADDITIONAL_COSTS_TYPES
};

const optionsGetDirectMarketingTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_DIRECT_MARKETING_TYPES
};

const optionsGetFoodTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_FOOD_TYPES
};

const optionsGetAudienceGenerationTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_AUDIENCE_GENERATION_TYPES
};

const optionsGetOtherCostTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_OTHER_COST_TYPES
};

const optionsGetRoomTypeTypesTypes: IFetchOptions = {
  method: EMethods.GET,
  URL: EVENT_BUDGET_URL_ACCOMMODATION_ROOM_TYPE_TYPES
};

const getContractLaborTypes = (): Promise<string[]> => fetchAPI(optionsGetContractLaborTypes);

const getShippingAndPostageTypes = (): Promise<string[]> => fetchAPI(optionsGetShippingAndPostage);

const getCreativeDevelopmentTypes = (): Promise<string[]> => fetchAPI(optionsGetCreativeDevelopment);

const getSupportCollateralsTypes = (): Promise<[]> => fetchAPI(optionsGetSupportCollaterals);

const getSignageTypes = (): Promise<string[]> => fetchAPI(optionsGetSignage);

const getAudioVisualTypes = (): Promise<GenericComponenteType[]> => fetchAPI(optionsGetAudioVisualTypes);

const getAdditionalCostsTypes = (): Promise<GenericComponenteType[]> => fetchAPI(optionsGetAdditionalCostsTypes);

const getDirectMarketingTypes = (): Promise<string[]> => fetchAPI(optionsGetDirectMarketingTypes);

const getFoodTypes = (): Promise<string[]> => fetchAPI(optionsGetFoodTypes);

const getAudienceGenerationTypes = (): Promise<string[]> => fetchAPI(optionsGetAudienceGenerationTypes);

const getOtherCostTypes = (): Promise<string[]> => fetchAPI(optionsGetOtherCostTypes);

const getRoomTypeTypes = (): Promise<string[]> => fetchAPI(optionsGetRoomTypeTypesTypes);

export {
  getBudgetTemplates,
  getComponents,
  addTemplate,
  cloneBudgetTemplate,
  archiveBudgetTemplate,
  getBudgetTemplateById,
  updateBudgetTemplate,
  assignTemplateToEvent,
  getContractLaborTypes,
  getShippingAndPostageTypes,
  getCreativeDevelopmentTypes,
  getSupportCollateralsTypes,
  getSignageTypes,
  getAudioVisualTypes,
  getAdditionalCostsTypes,
  getDirectMarketingTypes,
  getAudienceGenerationTypes,
  getOtherCostTypes,
  getRoomTypeTypes,
  getFoodTypes,
  updateWorkingBudgetTemplate,
  updateEventReconciliation,
  updateSimpleReconTemplate,
  createSimpleReconTemplate,
  approveEventChanges
};

export const amplifyConfig = {
  Auth: {
    region: process.env.REACT_APP_AWS_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    identityPoolId: process.env.REACT_APP_AWS_IDENTITY_POOL,
    userPoolWebClientId: process.env.REACT_APP_AWS_CLIENT_ID
  }
};

export const gtmConfig = {
  containerId: process.env.REACT_APP_GTM_CONTAINER_ID
};

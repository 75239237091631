import { ICountry } from './ICountry';
import { IState } from './IState';
import { IIndustry } from './IIndustry';

export interface IClient {
  message: string;
  pageNumber: number;
  pageSize: number;
  totalPages: number;
  totalRecords: number;
  resource: IClientUser[];
  success: boolean;
}

export interface IClientsUser {
  id?: string;
  client?: IClientUser;
  clientId?: string;
}
export interface IClientUser {
  id: string;
  name?: string;
  inactive?: boolean;
  template?: string;
  netsuite?: number;
  recordId?: string;
  description?: string;
  industryId?: number;
  industry?: IIndustry;
  address?: string;
  city?: string;
  state?: IState;
  stateId?: number;
  zipCode?: string;
  country?: ICountry;
  countryId?: number;
  phoneNumber?: string;
  billingAddress?: string;
  billingCity?: string;
  billingState?: IState;
  billingStateId?: number;
  billingZipCode?: string;
  billingCountry?: ICountry;
  billingCountryId?: number;
  billingPhoneNumber?: string;
  billingEmailAddress?: string;
  startDate?: Date;
  location?: string;
  linkedINProfile?: string;
  websiteLink?: string;
}

export interface IClientUserUpdate {
  clientId: string | number;
}

export class NullClients implements IClientsUser {
  public id = '';
  public clientId = '';
  public client = {
    id: '',
    name: ''
  };
}

export interface IClientsResponse {
  pageNumber: number;
  pageSize: number;
  totalPage: number;
  totalRecords: number;
  success: boolean;
  message: string;
  resource: IClientUser[];
}

/* eslint-disable @typescript-eslint/indent */
import { ManagementFee } from 'components/dashboard/wizard/tabs/programBudget/FinancialOptions/EventWizardManagementFee';
import { FoodDate } from 'components/dashboard/wizard/tabs/programBudget/FinancialOptions/FoodAndBeverage/EventWizardFoodAndBeverageFoodDate';
import {
  Action,
  UpdateEventBudget,
  UpdateEvent,
  UpdateCurrentFinancialOption,
  UpdateLastAutosaveTimer,
  UpdateEventByKey,
  UpdateEventBudgetDibursementsByKey,
  State,
  UpdateRoomTypeFinalPrice,
  UpdateContractLaborFinalPrice,
  UpdateShippingAndPostageFinalPrice,
  UpdateEventWorkingBudget,
  UpdateEventSimpleRecon,
  UdpateWorkingBudgetComponentByKey,
  UdpateWorkingBudgetByIndexFinalPrice,
  UdpateChangeOrderByIndexFinalPrice,
  DeleteLineOfWorkingBudgetByKey,
  UpdateEventReconciliation,
  UdpateReconciliationComponentByKey,
  DeleteChangeOrderComponentByKey
} from 'contexts/EventWizardBudgetContext';
import {
  AddRowOfComponentByKey,
  AddRowOfFoodQuantitieByKey,
  DeleteFoodQuantitieOfComponentByKey,
  DeleteManagementFee,
  DeleteRowOfComponentByKey,
  RecalculateRoomQuantitiesDate,
  ToggleMarkupTypeByKey,
  UdpateComponentByKey,
  UpdateAdditionalCostsFinalPrice,
  UpdateAmiInternalCostFinalPrice,
  UpdateAudienceGenerationFinalPrice,
  UpdateAudioVisualFinalPrice,
  UpdateBudgetName,
  UpdateBudgetTemplateId,
  UpdateBudgetType,
  UpdateCreativeDevelopmentFinalPrice,
  UpdateDirectMarketingFinalPrice,
  UpdateDisbursementsFinalPrice,
  UpdateEventBudgetMarketingAndAudienceGenerationByKey,
  UpdateEventBudgetProductionByKey,
  UpdateEventBudgetSignageAngGraphicsByKey,
  UpdateFinancialOptions,
  UpdateFoodAndBeverageFinalPrice,
  UpdateHonorariaFinalPrice,
  UpdateManagementFee,
  UpdateManagementFeeFinalPrice,
  UpdateOtherMarketingFinalPrice,
  UpdateRoomQUantitieByIndex,
  UpdateSignageFinalPrice,
  UpdateSupportCollateralsFinalPrice,
  UpdateTransportationAirFinalPrice,
  UpdateTransportationFinalPrice,
  UpdateTransportationGroundFinalPrice,
  UpdateVenueFinalPrice
} from 'contexts/EventWizardBudgetContext/actions';
import { EProfitMarkupType, EEventWorkingBudgetComponent } from 'types/IEvent';

const handlers: Record<string, (state: State, action: Action) => State> = {
  UPDATE_EVENT_BUDGET: (state: State, action: UpdateEventBudget): State => ({
    ...state,
    eventBudget: action.payload.eventBudget
  }),
  UPDATE_EVENT_WORKING_BUDGET: (state: State, action: UpdateEventWorkingBudget): State => ({
    ...state,
    eventWorkingBudget: action.payload.eventWorkingBudget
  }),
  UPDATE_EVENT_SIMPLE_RECON: (state: State, action: UpdateEventSimpleRecon): State => ({
    ...state,
    eventSimpleRecon: action.payload.eventSimpleRecon
  }),
  UPDATE_EVENT_RECONCILIATION: (state: State, action: UpdateEventReconciliation): State => ({
    ...state,
    eventReconciliation: action.payload.eventReconciliation
  }),
  UPDATE_EVENT: (state: State, action: UpdateEvent): State => ({ ...state, event: action.payload.event }),
  UPDATE_LAST_AUTOSAVE_TIMER: (state: State, action: UpdateLastAutosaveTimer): State => ({
    ...state,
    lastAutosaveTimer: action.payload.lastAutosaveTimer
  }),
  UPDATE_FINANCIAL_OPTION: (state: State, action: UpdateCurrentFinancialOption): State => ({
    ...state,
    currentFinancialOption: action.payload.option
  }),
  UPDATE_EVENT_BY_KEY: (state: State, action: UpdateEventByKey): State => ({
    ...state,
    event: {
      ...state.event,
      [action.payload.key]: action.payload.value
    }
  }),
  UPDATE_EVENT_BUDGET_DISBURSEMENTS_BY_KEY: (state: State, action: UpdateEventBudgetDibursementsByKey): State => ({
    ...state,
    eventBudget: {
      ...state.eventBudget,
      eventBudgetDisbursement: {
        ...state.eventBudget.eventBudgetDisbursement,
        [action.payload.key]: action.payload.value
      }
    }
  }),
  UPDATED_ROOM_TYPE_FINAL_PRICE: (state: State, action: UpdateRoomTypeFinalPrice): State => {
    const newState = { ...state };
    if (
      newState.eventBudget.eventBudgetAccommodation.roomTypes[action.payload.index].profitMarkupType ===
      EProfitMarkupType.PERCENTAJE
    ) {
      newState.eventBudget.eventBudgetAccommodation.roomTypes[action.payload.index].unitFinalBasePrice =
        ((Number(newState.eventBudget.eventBudgetAccommodation.roomTypes[action.payload.index].profitMarkup) + 100) *
          Number(newState.eventBudget.eventBudgetAccommodation.roomTypes[action.payload.index].baseCost)) /
        100;
    } else {
      newState.eventBudget.eventBudgetAccommodation.roomTypes[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetAccommodation.roomTypes[action.payload.index].profitMarkup) +
        Number(newState.eventBudget.eventBudgetAccommodation.roomTypes[action.payload.index].baseCost);
    }
    return newState;
  },
  UPDATED_CONTRACT_LABOR_FINAL_PRICE: (state: State, action: UpdateContractLaborFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].subtotal =
      newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].numberOfPeople *
      newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].numberOfUnits *
      Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].baseCost);
    if (
      newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].profitMarkup) +
        Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].baseCost);
      newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].total =
        Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].subtotal) +
        Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].profitMarkup) *
          Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].numberOfUnits) *
          Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].numberOfPeople);
    } else {
      newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].unitFinalBasePrice =
        ((Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].profitMarkup) +
          100) *
          Number(newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].baseCost)) /
        100;
      newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].total =
        newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].subtotal *
        (1 + newState.eventBudget.eventBudgetContractLabor.contractLabors[action.payload.index].profitMarkup / 100);
    }
    return newState;
  },
  UPDATED_SHIPPING_AND_POSTAGE_FINAL_PRICE: (state: State, action: UpdateShippingAndPostageFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].subtotal =
      newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].quantity *
      Number(newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].baseCost);
    if (
      newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].profitMarkup
        ) +
        Number(newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].baseCost);
      newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].total =
        Number(newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].subtotal) +
        Number(
          newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].profitMarkup
        ) *
          Number(newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].quantity);
    } else {
      newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].baseCost) +
        (Number(newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].baseCost) *
          Number(
            newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].profitMarkup
          )) /
          100;
      newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].total =
        newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetShippingAndPostage.shippingAndPostage[action.payload.index].profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_CREATIVE_DEVELOPMENT_FINAL_PRICE: (state: State, action: UpdateCreativeDevelopmentFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].subtotal =
      Number(newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].quantity) *
      Number(newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].baseCost);
    if (
      newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].profitMarkup
        ) +
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].baseCost);
      newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].total =
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].subtotal) +
        Number(
          newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].profitMarkup
        ) *
          Number(
            newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].quantity
          );
    } else {
      newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].baseCost) +
        (Number(
          newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].baseCost
        ) *
          Number(
            newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].profitMarkup
          )) /
          100;
      newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].total =
        newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetSignageAngGraphics.creativeDevelopments[action.payload.index].profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_SUPPORT_COLLATERAL_FINAL_PRICE: (state: State, action: UpdateSupportCollateralsFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].subtotal =
      Number(newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].quantity) *
      Number(newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].baseCost);
    if (
      newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].profitMarkup
        ) +
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].baseCost);
      newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].total =
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].subtotal) +
        Number(
          newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].profitMarkup
        ) *
          Number(newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].quantity);
    } else {
      newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].baseCost) +
        (Number(newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].baseCost) *
          Number(
            newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].profitMarkup
          )) /
          100;
      newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].total =
        newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetSignageAngGraphics.supportCollaterals[action.payload.index].profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_SIGNAGE_FINAL_PRICE: (state: State, action: UpdateSignageFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].subtotal =
      Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].quantity) *
      Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].baseCost);
    if (
      newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].profitMarkup) +
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].baseCost);
      newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].total =
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].subtotal) +
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].profitMarkup) *
          Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].quantity);
    } else {
      newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].baseCost) +
        (Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].baseCost) *
          Number(newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].profitMarkup)) /
          100;
      newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].total =
        newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetSignageAngGraphics.signages[action.payload.index].profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_HONORARIA_TYPE_FINAL_PRICE: (state: State, action: UpdateHonorariaFinalPrice): State => {
    const newState = { ...state };
    if (newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index]) {
      newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].subtotal =
        Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].quantity) *
        Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].fee);
      if (
        newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].profitMarkupType ===
        EProfitMarkupType.PERCENTAJE
      ) {
        newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].unitFinalBasePrice =
          Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].fee) +
          (Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].fee) *
            Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].profitMarkup)) /
            100;
        newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].total =
          Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].subtotal) +
          (Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].subtotal) *
            Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].profitMarkup)) /
            100;
      } else {
        newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].unitFinalBasePrice =
          Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].fee) +
          Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].profitMarkup);
        newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].total =
          Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].subtotal) +
          Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].quantity) *
            Number(newState.eventBudget.eventBudgetDisbursement.honorarias[action.payload.index].profitMarkup);
      }
      const managementFeePerHeadSummatory = newState.eventBudget.eventBudgetDisbursement.honorarias.reduce(
        (prev, honoraria) => (prev += Number(honoraria.quantity)),
        0
      );
      if (newState?.eventBudget?.eventBudgetDisbursement?.managementFeePerHead > 0) {
        newState.eventBudget.eventBudgetDisbursement.managementFeeTotal =
          managementFeePerHeadSummatory * Number(newState.eventBudget.eventBudgetDisbursement.managementFeePerHead);
      }
    }
    return newState;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  UPDATE_DISBURSEMENTS_FINAL_PRICE: (state: State, action: UpdateDisbursementsFinalPrice): State => {
    const newState = { ...state };

    newState.eventBudget.eventBudgetDisbursement.expenseAttendeesSubtotal =
      Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesQuantity) *
      Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesCostPerDay);
    newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesSubtotal =
      Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesQuantity) *
      Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesCostPerDay);

    newState.eventBudget.eventBudgetDisbursement.expenseClientsSubtotal =
      Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsQuantity) *
      Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsCostPerDay);
    newState.eventBudget.eventBudgetDisbursement.perDiemClientsSubtotal =
      Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsQuantity) *
      Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsCostPerDay);

    newState.eventBudget.eventBudgetDisbursement.expenseStaffSubtotal =
      Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffQuantity) *
      Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffCostPerDay);
    newState.eventBudget.eventBudgetDisbursement.perDiemStaffSubtotal =
      Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffQuantity) *
      Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffCostPerDay);

    if (
      newState.eventBudget.eventBudgetDisbursement.expenseAttendeesProfitMarkupType === EProfitMarkupType.PERCENTAJE
    ) {
      newState.eventBudget.eventBudgetDisbursement.expenseAttendeesUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesCostPerDay) +
        (Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesCostPerDay) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesProfitMarkup)) /
          100;
      newState.eventBudget.eventBudgetDisbursement.expenseAttendeesTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesSubtotal) *
        Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesNumberOfDays) *
        (1 + Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetDisbursement.expenseAttendeesUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesProfitMarkup) +
        Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesProfitMarkup);
      newState.eventBudget.eventBudgetDisbursement.expenseAttendeesTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesSubtotal) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesNumberOfDays) +
        Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesProfitMarkup) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseAttendeesNumberOfDays);
    }
    if (
      newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesProfitMarkupType === EProfitMarkupType.PERCENTAJE
    ) {
      newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesCostPerDay) +
        (Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesCostPerDay) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesProfitMarkup)) /
          100;
      newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesSubtotal) *
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesNumberOfDays) *
        (1 + Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesProfitMarkup) +
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesProfitMarkup);
      newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesSubtotal) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesNumberOfDays) +
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesProfitMarkup) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemAttendeesNumberOfDays);
    }

    if (newState.eventBudget.eventBudgetDisbursement.expenseClientsProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetDisbursement.expenseClientsUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsCostPerDay) +
        (Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsCostPerDay) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsProfitMarkup)) /
          100;
      newState.eventBudget.eventBudgetDisbursement.expenseClientsTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsSubtotal) *
        Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsNumberOfDays) *
        (1 + Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetDisbursement.expenseClientsUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsProfitMarkup) +
        Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsProfitMarkup);
      newState.eventBudget.eventBudgetDisbursement.expenseClientsTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsSubtotal) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsNumberOfDays) +
        Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsProfitMarkup) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseClientsNumberOfDays);
    }
    if (newState.eventBudget.eventBudgetDisbursement.perDiemClientsProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetDisbursement.perDiemClientsUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsCostPerDay) +
        (Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsCostPerDay) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsProfitMarkup)) /
          100;
      newState.eventBudget.eventBudgetDisbursement.perDiemClientsTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsSubtotal) *
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsNumberOfDays) *
        (1 + Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetDisbursement.perDiemClientsUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsProfitMarkup) +
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsProfitMarkup);
      newState.eventBudget.eventBudgetDisbursement.perDiemClientsTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsSubtotal) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsNumberOfDays) +
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsProfitMarkup) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemClientsNumberOfDays);
    }

    if (newState.eventBudget.eventBudgetDisbursement.expenseStaffProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetDisbursement.expenseStaffUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffCostPerDay) +
        (Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffCostPerDay) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffProfitMarkup)) /
          100;
      newState.eventBudget.eventBudgetDisbursement.expenseStaffTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffSubtotal) *
        Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffNumberOfDays) *
        (1 + Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetDisbursement.expenseStaffUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffProfitMarkup) +
        Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffProfitMarkup);
      newState.eventBudget.eventBudgetDisbursement.expenseStaffTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffSubtotal) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffNumberOfDays) +
        Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffProfitMarkup) *
          Number(newState.eventBudget.eventBudgetDisbursement.expenseStaffNumberOfDays);
    }
    if (newState.eventBudget.eventBudgetDisbursement.perDiemStaffProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetDisbursement.perDiemStaffUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffCostPerDay) +
        (Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffCostPerDay) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffProfitMarkup)) /
          100;
      newState.eventBudget.eventBudgetDisbursement.perDiemStaffTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffSubtotal) *
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffNumberOfDays) *
        (1 + Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetDisbursement.perDiemStaffUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffProfitMarkup) +
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffProfitMarkup);
      newState.eventBudget.eventBudgetDisbursement.perDiemStaffTotal =
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffSubtotal) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffNumberOfDays) +
        Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffProfitMarkup) *
          Number(newState.eventBudget.eventBudgetDisbursement.perDiemStaffNumberOfDays);
    }
    return newState;
  },
  RECALCULATE_ROOM_QUANTITIES_DATE: (state: State, action: RecalculateRoomQuantitiesDate): State => {
    const newState = { ...state };
    newState?.eventBudget?.eventBudgetAccommodation?.roomTypes?.forEach((roomType) => {
      roomType.roomQuantities = action.payload.newRoomQuantities.map((date) => {
        const date1 = new Date(date);
        const year1 = date1.getFullYear();
        const month1 = date1.getMonth();
        const day1 = date1.getDate();
        const i = roomType?.roomQuantities.findIndex((v) => {
          const date2 = new Date(v.date);
          const year2 = date2.getFullYear();
          const month2 = date2.getMonth();
          const day2 = date2.getDate();
          return year1 === year2 && month1 === month2 && day1 === day2;
        });
        return {
          quantity: roomType?.roomQuantities[i]?.quantity || 0,
          date,
          id: roomType?.roomQuantities[i]?.id
        };
      });
    });
    return newState;
  },
  UPDATE_ROOM_QUANTITIE_BY_INDEX: (state: State, action: UpdateRoomQUantitieByIndex): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetAccommodation.roomTypes[action.payload.indexRoomType].roomQuantities[
      action.payload.indexRoomQuantitie
    ].quantity = action.payload.value;
    return newState;
  },
  UPDATE_FINANCIAL_OPTIONS: (state: State, action: UpdateFinancialOptions): State => ({
    ...state,
    financialOptions: action.payload.options
  }),
  UPDATE_BUDGET_NAME: (state: State, action: UpdateBudgetName): State => ({
    ...state,
    name: action.payload.name
  }),
  UPDATE_BUDGET_TYPE: (state: State, action: UpdateBudgetType): State => ({
    ...state,
    currentBudgetType: action.payload.type
  }),
  UPDATE_BUDGET_TEMPLATE_ID: (state: State, action: UpdateBudgetTemplateId): State => ({
    ...state,
    budgetTemplateId: action.payload.id
  }),
  UPDATE_EVENT_BUDGET_PRODUCTION_BY_KEY: (state: State, action: UpdateEventBudgetProductionByKey): State => ({
    ...state,
    eventBudget: {
      ...state.eventBudget,
      eventBudgetProduction: {
        ...state.eventBudget.eventBudgetProduction,
        [action.payload.key]: action.payload.value
      }
    }
  }),
  UPDATE_EVENT_SIGNAGE_AND_GRAPHICS_BY_KEY: (
    state: State,
    action: UpdateEventBudgetSignageAngGraphicsByKey
  ): State => ({
    ...state,
    eventBudget: {
      ...state.eventBudget,
      eventBudgetSignageAngGraphics: {
        ...state.eventBudget.eventBudgetSignageAngGraphics,
        [action.payload.key]: action.payload.value
      }
    }
  }),
  UPDATE_EVENT_BUDGET_MARKETING_AND_AUDIENCE_GENERATION_BY_KEY: (
    state: State,
    action: UpdateEventBudgetMarketingAndAudienceGenerationByKey
  ): State => ({
    ...state,
    eventBudget: {
      ...state.eventBudget,
      eventBudgetMarketingAndAudienceGeneration: {
        ...state.eventBudget.eventBudgetMarketingAndAudienceGeneration,
        [action.payload.key]: action.payload.value
      }
    }
  }),
  UPDATED_AUDIO_VISUAL_FINAL_PRICE: (state: State, action: UpdateAudioVisualFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].subtotal =
      Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].quantity) *
      Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].baseCost) *
      Number(Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].numberOfDay));
    if (
      newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].profitMarkup) +
        Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].baseCost);
      newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].total =
        Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].subtotal) +
        Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].profitMarkup) *
          Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].quantity) *
          Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].numberOfDay);
    } else {
      newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].baseCost) +
        (Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].baseCost) *
          Number(newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].profitMarkup)) /
          100;
      newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].total =
        newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetProduction.audioVisuals[action.payload.index].profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_VENUE_FINAL_PRICE: (state: State, action: UpdateVenueFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetProduction.venues[action.payload.index].subtotal =
      Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].quantity) *
      Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].baseCost) *
      Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].numberOfDay);
    if (
      newState.eventBudget.eventBudgetProduction.venues[action.payload.index].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetProduction.venues[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].profitMarkup) +
        Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].baseCost);
      newState.eventBudget.eventBudgetProduction.venues[action.payload.index].total =
        Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].subtotal) +
        Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].profitMarkup) *
          Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].quantity) *
          Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].numberOfDay);
    } else {
      newState.eventBudget.eventBudgetProduction.venues[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].baseCost) +
        (Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].baseCost) *
          Number(newState.eventBudget.eventBudgetProduction.venues[action.payload.index].profitMarkup)) /
          100;
      newState.eventBudget.eventBudgetProduction.venues[action.payload.index].total =
        newState.eventBudget.eventBudgetProduction.venues[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetProduction.venues[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetProduction.venues[action.payload.index].profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_ADDITIONAL_COSTS_FINAL_PRICE: (state: State, action: UpdateAdditionalCostsFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].subtotal =
      Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].quantity) *
      Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].baseCost);
    if (
      newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].profitMarkup) +
        Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].baseCost);
      newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].total =
        Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].subtotal) +
        Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].profitMarkup) *
          Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].quantity);
    } else {
      newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].unitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].baseCost) +
        (Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].baseCost) *
          Number(newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].profitMarkup)) /
          100;
      newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].total =
        newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetProduction.additionalCosts[action.payload.index].profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_DIRECT_MARKETING_FINAL_PRICE: (state: State, action: UpdateDirectMarketingFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].subtotal =
      Number(
        newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].quantity
      ) *
      Number(
        newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].baseCost
      );
    if (
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index]
        .profitMarkupType === EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[
        action.payload.index
      ].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index]
            .profitMarkup
        ) +
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].baseCost
        );
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].total =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].subtotal
        ) +
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index]
            .profitMarkup
        ) *
          Number(
            newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index]
              .quantity
          );
    } else {
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[
        action.payload.index
      ].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].baseCost
        ) +
        (Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].baseCost
        ) *
          Number(
            newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index]
              .profitMarkup
          )) /
          100;
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].total =
        newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.directMarketing[action.payload.index]
            .profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_AUDIENCE_GENERATION_FINAL_PRICE: (state: State, action: UpdateAudienceGenerationFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index].subtotal =
      Number(
        newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
          .quantity
      ) *
      Number(
        newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
          .baseCost
      );
    if (
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
        .profitMarkupType === EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[
        action.payload.index
      ].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
            .profitMarkup
        ) +
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
            .baseCost
        );
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index].total =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
            .subtotal
        ) +
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
            .profitMarkup
        ) *
          Number(
            newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
              .quantity
          );
    } else {
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[
        action.payload.index
      ].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
            .baseCost
        ) +
        (Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
            .baseCost
        ) *
          Number(
            newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
              .profitMarkup
          )) /
          100;
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index].total =
        newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
          .subtotal +
        (newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
          .subtotal *
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.audienceGenerations[action.payload.index]
            .profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_OTHER_MARKETING_FINAL_PRICE: (state: State, action: UpdateOtherMarketingFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].subtotal =
      Number(newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].quantity) *
      Number(newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].baseCost);
    if (
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index]
        .profitMarkupType === EProfitMarkupType.DOLLAR
    ) {
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[
        action.payload.index
      ].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].profitMarkup
        ) +
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].baseCost
        );
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].total =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].subtotal
        ) +
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].profitMarkup
        ) *
          Number(
            newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].quantity
          );
    } else {
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[
        action.payload.index
      ].unitFinalBasePrice =
        Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].baseCost
        ) +
        (Number(
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].baseCost
        ) *
          Number(
            newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].profitMarkup
          )) /
          100;
      newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].total =
        newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].subtotal +
        (newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index].subtotal *
          newState.eventBudget.eventBudgetMarketingAndAudienceGeneration.otherCosts[action.payload.index]
            .profitMarkup) /
          100;
    }
    return newState;
  },
  UPDATED_AMI_INTERNAL_COST_FINAL_PRICE: (state: State, action: UpdateAmiInternalCostFinalPrice): State => {
    const newState = { ...state };
    const { index } = action.payload;
    newState.eventBudget.eventBudgetAmiInternalCost.amiInternalCosts[index].total =
      Number(newState?.eventBudget?.eventBudgetAmiInternalCost?.amiInternalCosts[index]?.perItemFee || 0) *
      Number(newState?.eventBudget?.eventBudgetAmiInternalCost?.amiInternalCosts[index]?.quantity || 0);
    return newState;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  UPDATE_TRANSPORTATION_GROUND_FINAL_PRICE: (state: State, action: UpdateTransportationGroundFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetTransportation.ground.amiStaffSubtotal =
      Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffQuantity) *
      Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffBaseCost);

    newState.eventBudget.eventBudgetTransportation.ground.clientsSubtotal =
      Number(newState.eventBudget.eventBudgetTransportation.ground.clientsQuantity) *
      Number(newState.eventBudget.eventBudgetTransportation.ground.clientsBaseCost);

    newState.eventBudget.eventBudgetTransportation.ground.attendeesSubtotal =
      Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesQuantity) *
      Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesBaseCost);

    newState.eventBudget.eventBudgetTransportation.ground.otherSubtotal =
      Number(newState.eventBudget.eventBudgetTransportation.ground.otherQuantity) *
      Number(newState.eventBudget.eventBudgetTransportation.ground.otherBaseCost);

    if (
      newState.eventBudget.eventBudgetTransportation.ground.amiStaffProfitMarkupType === EProfitMarkupType.PERCENTAJE
    ) {
      newState.eventBudget.eventBudgetTransportation.ground.amiStaffUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffBaseCost) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffProfitMarkup) / 100);
      newState.eventBudget.eventBudgetTransportation.ground.amiStaffTotal =
        Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffSubtotal) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetTransportation.ground.amiStaffUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffProfitMarkup);

      newState.eventBudget.eventBudgetTransportation.ground.amiStaffTotal =
        Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffProfitMarkup) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffQuantity) / 100);
    }

    if (
      newState.eventBudget.eventBudgetTransportation.ground.clientsProfitMarkupType === EProfitMarkupType.PERCENTAJE
    ) {
      newState.eventBudget.eventBudgetTransportation.ground.clientsUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.ground.clientsBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.clientsBaseCost) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.clientsProfitMarkup) / 100);
      newState.eventBudget.eventBudgetTransportation.ground.clientsTotal =
        Number(newState.eventBudget.eventBudgetTransportation.ground.clientsSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.clientsSubtotal) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.clientsProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetTransportation.ground.clientsUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.ground.clientsBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.clientsProfitMarkup);
      newState.eventBudget.eventBudgetTransportation.ground.clientsTotal =
        Number(newState.eventBudget.eventBudgetTransportation.ground.clientsSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.clientsProfitMarkup) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.clientsQuantity) / 100);
    }

    if (
      newState.eventBudget.eventBudgetTransportation.ground.attendeesProfitMarkupType === EProfitMarkupType.PERCENTAJE
    ) {
      newState.eventBudget.eventBudgetTransportation.ground.attendeesUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesBaseCost) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesProfitMarkup) / 100);
      newState.eventBudget.eventBudgetTransportation.ground.attendeesTotal =
        Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesSubtotal) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetTransportation.ground.attendeesUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesProfitMarkup);
      newState.eventBudget.eventBudgetTransportation.ground.attendeesTotal =
        Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesProfitMarkup) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesQuantity) / 100);
    }

    if (newState.eventBudget.eventBudgetTransportation.ground.otherProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetTransportation.ground.otherUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.ground.otherBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.otherBaseCost) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.otherProfitMarkup) / 100);
      newState.eventBudget.eventBudgetTransportation.ground.otherTotal =
        Number(newState.eventBudget.eventBudgetTransportation.ground.otherSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.otherSubtotal) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.otherProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetTransportation.ground.otherUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.ground.otherBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.otherProfitMarkup);
      newState.eventBudget.eventBudgetTransportation.ground.otherTotal =
        Number(newState.eventBudget.eventBudgetTransportation.ground.otherSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.ground.otherProfitMarkup) *
          (Number(newState.eventBudget.eventBudgetTransportation.ground.otherQuantity) / 100);
    }

    newState.eventBudget.eventBudgetTransportation.ground.total =
      Number(newState.eventBudget.eventBudgetTransportation.ground.otherTotal) +
      Number(newState.eventBudget.eventBudgetTransportation.ground.attendeesTotal) +
      Number(newState.eventBudget.eventBudgetTransportation.ground.clientsTotal) +
      Number(newState.eventBudget.eventBudgetTransportation.ground.amiStaffTotal);

    return newState;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  UPDATE_TRANSPORTATION_FINAL_PRICE: (state: State, action: UpdateTransportationFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetTransportation.perTicketTotal =
      Number(newState.eventBudget.eventBudgetTransportation.amiTicketManagementCostPerTicket) +
      Number(newState.eventBudget.eventBudgetTransportation.amiTicketManagementFeePerTicket);

    const staffSummatory =
      Number(newState?.eventBudget?.eventBudgetTransportation?.air?.amiStaffQuantity) +
      Number(newState?.eventBudget?.eventBudgetTransportation?.air?.otherQuantity) +
      Number(newState?.eventBudget?.eventBudgetTransportation?.air?.clientsQuantity) +
      Number(newState?.eventBudget?.eventBudgetTransportation?.air?.attendeesQuantity);

    newState.eventBudget.eventBudgetTransportation.managementFeeTotal =
      newState.eventBudget.eventBudgetTransportation.perTicketTotal * staffSummatory;

    const totalSummatory =
      Number(newState?.eventBudget?.eventBudgetTransportation?.air?.amiStaffTotal) +
      Number(newState?.eventBudget?.eventBudgetTransportation?.air?.otherTotal) +
      Number(newState?.eventBudget?.eventBudgetTransportation?.air?.clientsTotal) +
      Number(newState?.eventBudget?.eventBudgetTransportation?.air?.attendeesTotal);

    newState.eventBudget.eventBudgetTransportation.grandTotal =
      newState?.eventBudget?.eventBudgetTransportation?.managementFeeTotal + totalSummatory;
    return newState;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  UPDATED_FOOD_AND_BEVERAGE_FINAL_PRICE: (state: State, action: UpdateFoodAndBeverageFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[action.payload.index].foodQuantities.forEach(
      (foodQuantitie) => {
        foodQuantitie.subtotal = Number(foodQuantitie.quantity) * Number(foodQuantitie.baseCost);
        if (foodQuantitie.profitMarkupType === EProfitMarkupType.DOLLAR) {
          foodQuantitie.unitFinalBasePrice = foodQuantitie.profitMarkup + Number(foodQuantitie.baseCost);
        } else {
          foodQuantitie.unitFinalBasePrice =
            Number(foodQuantitie.baseCost) + (Number(foodQuantitie.baseCost) * foodQuantitie.profitMarkup) / 100;
        }
        foodQuantitie.total = Number(foodQuantitie.quantity) * Number(foodQuantitie.unitFinalBasePrice);
      }
    );
    return newState;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  UPDATE_TRANSPORTATION_AIR_FINAL_PRICE: (state: State, action: UpdateTransportationAirFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetTransportation.air.amiStaffSubtotal =
      Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffQuantity) *
      Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffBaseCost);

    newState.eventBudget.eventBudgetTransportation.air.clientsSubtotal =
      Number(newState.eventBudget.eventBudgetTransportation.air.clientsQuantity) *
      Number(newState.eventBudget.eventBudgetTransportation.air.clientsBaseCost);

    newState.eventBudget.eventBudgetTransportation.air.attendeesSubtotal =
      Number(newState.eventBudget.eventBudgetTransportation.air.attendeesQuantity) *
      Number(newState.eventBudget.eventBudgetTransportation.air.attendeesBaseCost);

    newState.eventBudget.eventBudgetTransportation.air.otherSubtotal =
      Number(newState.eventBudget.eventBudgetTransportation.air.otherQuantity) *
      Number(newState.eventBudget.eventBudgetTransportation.air.otherBaseCost);

    if (newState.eventBudget.eventBudgetTransportation.air.amiStaffProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetTransportation.air.amiStaffUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffBaseCost) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffProfitMarkup) / 100);
      newState.eventBudget.eventBudgetTransportation.air.amiStaffTotal =
        Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffSubtotal) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetTransportation.air.amiStaffUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffProfitMarkup);
      newState.eventBudget.eventBudgetTransportation.air.amiStaffTotal =
        Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffProfitMarkup) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.amiStaffQuantity) / 100);
    }

    if (newState.eventBudget.eventBudgetTransportation.air.clientsProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetTransportation.air.clientsUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.air.clientsBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.air.clientsBaseCost) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.clientsProfitMarkup) / 100);
      newState.eventBudget.eventBudgetTransportation.air.clientsTotal =
        Number(newState.eventBudget.eventBudgetTransportation.air.clientsSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.air.clientsSubtotal) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.clientsProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetTransportation.air.clientsUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.air.clientsBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.air.clientsProfitMarkup);
      newState.eventBudget.eventBudgetTransportation.air.clientsTotal =
        Number(newState.eventBudget.eventBudgetTransportation.air.clientsSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.air.clientsProfitMarkup) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.clientsQuantity) / 100);
    }

    if (newState.eventBudget.eventBudgetTransportation.air.attendeesProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetTransportation.air.attendeesUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.air.attendeesBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.air.attendeesBaseCost) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.attendeesProfitMarkup) / 100);
      newState.eventBudget.eventBudgetTransportation.air.attendeesTotal =
        Number(newState.eventBudget.eventBudgetTransportation.air.attendeesSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.air.attendeesSubtotal) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.attendeesProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetTransportation.air.attendeesUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.air.attendeesBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.air.attendeesProfitMarkup);
      newState.eventBudget.eventBudgetTransportation.air.attendeesTotal =
        Number(newState.eventBudget.eventBudgetTransportation.air.attendeesSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.air.attendeesProfitMarkup) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.attendeesQuantity) / 100);
    }

    if (newState.eventBudget.eventBudgetTransportation.air.otherProfitMarkupType === EProfitMarkupType.PERCENTAJE) {
      newState.eventBudget.eventBudgetTransportation.air.otherUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.air.otherBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.air.otherBaseCost) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.otherProfitMarkup) / 100);
      newState.eventBudget.eventBudgetTransportation.air.otherTotal =
        Number(newState.eventBudget.eventBudgetTransportation.air.otherSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.air.otherSubtotal) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.otherProfitMarkup) / 100);
    } else {
      newState.eventBudget.eventBudgetTransportation.air.otherUnitFinalBasePrice =
        Number(newState.eventBudget.eventBudgetTransportation.air.otherBaseCost) +
        Number(newState.eventBudget.eventBudgetTransportation.air.otherProfitMarkup);
      newState.eventBudget.eventBudgetTransportation.air.otherTotal =
        Number(newState.eventBudget.eventBudgetTransportation.air.otherSubtotal) +
        Number(newState.eventBudget.eventBudgetTransportation.air.otherProfitMarkup) *
          (Number(newState.eventBudget.eventBudgetTransportation.air.otherQuantity) / 100);
    }

    return newState;
  },
  ADD_ROW_OF_FOOD_QUANTITIE_BY_KEY: (state: State, action: AddRowOfFoodQuantitieByKey): State => {
    const { rowObject, indexKey } = action.payload;
    const newState = { ...state };
    newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[indexKey] = {
      ...newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[indexKey],
      foodQuantities: [
        ...(newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[indexKey]?.foodQuantities || []),
        rowObject
      ]
    };
    return newState;
  },
  UPDATE_FOOD_QUANTITIE_COMPONENT_BY_KEY: (state: State, action: UdpateComponentByKey): State => {
    const newState = { ...state };
    const { key, index, attribute, value } = action.payload;
    if (attribute?.includes('ProfitMarkupType')) {
      newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[index].foodQuantities[key].profitMarkupType =
        newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[index].foodQuantities[key].profitMarkupType ===
        EProfitMarkupType.DOLLAR
          ? EProfitMarkupType.PERCENTAJE
          : EProfitMarkupType.DOLLAR;
      return newState;
    }
    if (attribute === 'date') {
      if (newState?.eventBudget?.eventBudgetFoodAndBeverage?.foodDates[index]) {
        newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[index].date = value;
      } else {
        newState.eventBudget.eventBudgetFoodAndBeverage.foodDates.push(new FoodDate(value));
      }
      return newState;
    }
    if (attribute === 'removeDates') {
      const numberOfDatesShouldHave = value;
      const numberOfDatesToRemove =
        newState?.eventBudget?.eventBudgetFoodAndBeverage?.foodDates.length - numberOfDatesShouldHave;
      if (numberOfDatesToRemove) {
        newState.eventBudget.eventBudgetFoodAndBeverage.foodDates.splice(
          newState.eventBudget.eventBudgetFoodAndBeverage.foodDates.length - numberOfDatesToRemove,
          numberOfDatesToRemove
        );
      }

      return newState;
    }

    if (attribute === 'switchOrder') {
      const newIndex = value as number;
      let { foodQuantities } = newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[index];
      const item = foodQuantities[key];
      const ids = foodQuantities.map((food) => food.id);
      foodQuantities.splice(Number(key), 1);
      foodQuantities.splice(newIndex, 0, item);
      foodQuantities = foodQuantities.map((fq, i) => {
        fq.id = ids[i];
        return fq;
      });
      // Keep the same ids order to avoid BE reorder
      newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[index].foodQuantities = foodQuantities;
      return newState;
    }
    newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[index].foodQuantities[key][attribute] = value;
    return newState;
  },
  DELETE_FOOD_QUANTITIE_COMPONENT_BY_KEY: (state: State, action: DeleteFoodQuantitieOfComponentByKey): State => {
    const { key, index } = action.payload;
    const newState = { ...state };
    newState.eventBudget.eventBudgetFoodAndBeverage.foodDates[index].foodQuantities.splice(key, 1);
    return newState;
  },
  ADD_ROW_OF_COMPONENT_BY_KEY: (state: State, action: AddRowOfComponentByKey): State => {
    const { component, key, rowObject } = action.payload;
    return {
      ...state,
      eventBudget: {
        ...state.eventBudget,
        [component]: {
          ...state.eventBudget[component],
          [key]: [...state.eventBudget[component][key], rowObject]
        }
      }
    };
  },
  DELETE_ROW_OF_COMPONENT_BY_KEY: (state: State, action: DeleteRowOfComponentByKey): State => {
    const { component, key, index } = action.payload;
    const newState = { ...state };
    newState.eventBudget[component][key].splice(index, 1);
    return newState;
  },
  UPDATE_COMPONENT_BY_KEY: (state: State, action: UdpateComponentByKey): State => {
    const newState = { ...state };
    const { component, key, index, attribute, value } = action.payload;
    if (attribute?.includes('ProfitMarkupType')) {
      if (index) {
        newState.eventBudget[component][key][index] = {
          ...newState.eventBudget[component][key][index],
          [attribute]:
            newState.eventBudget[component][key][index][attribute] === EProfitMarkupType.DOLLAR
              ? EProfitMarkupType.PERCENTAJE
              : EProfitMarkupType.DOLLAR
        };
      } else {
        newState.eventBudget[component][key] = {
          ...newState.eventBudget[component][key],
          [attribute]:
            newState.eventBudget[component][key][attribute] === EProfitMarkupType.DOLLAR
              ? EProfitMarkupType.PERCENTAJE
              : EProfitMarkupType.DOLLAR
        };
      }
      return newState;
    }
    if (key?.includes('ProfitMarkupType') && !attribute) {
      if (index) {
        newState.eventBudget[component][index] = {
          ...state.eventBudget[component][index],
          [key]:
            newState.eventBudget[component][index][key] === EProfitMarkupType.DOLLAR
              ? EProfitMarkupType.PERCENTAJE
              : EProfitMarkupType.DOLLAR
        };
      } else {
        newState.eventBudget[component] = {
          ...state.eventBudget[component],
          [key]:
            newState.eventBudget[component][key] === EProfitMarkupType.DOLLAR
              ? EProfitMarkupType.PERCENTAJE
              : EProfitMarkupType.DOLLAR
        };
      }
      return newState;
    }
    if (index !== null) {
      newState.eventBudget[component][key][index] = {
        ...newState.eventBudget[component][key][index],
        [attribute]: value
      };
      return newState;
    }
    if (attribute) {
      newState.eventBudget[component][key] = {
        ...newState.eventBudget[component][key],
        [attribute]: value
      };
      return newState;
    }
    newState.eventBudget[component][key] = value;
    return newState;
  },
  UPDATE_RECONCILIATION_COMPONENT_BY_KEY: (state: State, action: UdpateReconciliationComponentByKey): State => {
    const newState = { ...state };
    const { index, value, component } = action.payload;
    if (index === undefined || !value) return newState;
    newState.eventReconciliation[component][index].comments = value;
    return newState;
  },
  UPDATE_WORKING_BUDGET_COMPONENT_BY_KEY: (state: State, action: UdpateWorkingBudgetComponentByKey): State => {
    const newState = { ...state };
    const { component, index, attribute, value } = action.payload;
    if (!component || !attribute) return newState;
    if (attribute?.includes('profitMarkupType')) {
      newState.eventWorkingBudget[component][index] = {
        ...newState.eventWorkingBudget[component][index],
        [attribute]:
          newState.eventWorkingBudget[component][index][attribute] === EProfitMarkupType.DOLLAR
            ? EProfitMarkupType.PERCENTAJE
            : EProfitMarkupType.DOLLAR
      };
      return newState;
    }
    if (index === null || index === undefined) return newState;
    if (component === EEventWorkingBudgetComponent.eventWorkingCommissionsAndTaxes) {
      newState.eventWorkingBudget[component][attribute] = value;
      return newState;
    }
    newState.eventWorkingBudget[component][index][attribute] = value;
    return newState;
  },
  UPDATE_WORKING_BUDGET_BY_INDEX_FINAL_PRICE: (state: State, action: UdpateWorkingBudgetByIndexFinalPrice): State => {
    const newState = { ...state };
    const { index, component } = action.payload;
    if (index === undefined || index === null || !component) return newState;
    if (component === EEventWorkingBudgetComponent.eventWorkingBudgetInternalCostLines) {
      newState.eventWorkingBudget[component][index].amiCostTotal =
        Number(newState.eventWorkingBudget[component][index].actual) *
        Number(newState.eventWorkingBudget[component][index].quantity);
      return newState;
    }
    const isMeetingFeeAndIsAMIExpense = (): boolean =>
      newState.eventWorkingBudget[component][index]?.componentType?.toLowerCase().includes('management') &&
      newState.eventWorkingBudget[component][index]?.descriptor?.toLowerCase().includes('ami');

    if (newState.eventWorkingBudget[component][index].profitMarkupType === EProfitMarkupType.DOLLAR) {
      newState.eventWorkingBudget[component][index].clientCostEach = isMeetingFeeAndIsAMIExpense()
        ? 0
        : Number(newState.eventWorkingBudget[component][index].baseCost) +
          Number(newState.eventWorkingBudget[component][index].markup);
    } else {
      newState.eventWorkingBudget[component][index].clientCostEach = isMeetingFeeAndIsAMIExpense()
        ? 0
        : Number(newState.eventWorkingBudget[component][index].baseCost) +
          Number(newState.eventWorkingBudget[component][index].markup) *
            (Number(newState.eventWorkingBudget[component][index].baseCost) / 100);
    }
    newState.eventWorkingBudget[component][index].clientSubtotal =
      Number(newState.eventWorkingBudget[component][index].clientCostEach) *
      Number(newState.eventWorkingBudget[component][index].quantity) *
      Number(newState.eventWorkingBudget[component][index].units);

    if (component === EEventWorkingBudgetComponent.eventWorkingBudgetAccommodationLines) {
      newState.eventWorkingBudget[component][index].amiCostTotal =
        Number(newState.eventWorkingBudget[component][index].baseCost) *
          Number(newState.eventWorkingBudget[component][index].quantity) *
          Number(newState.eventWorkingBudget[component][index].units) +
        Number(newState.eventWorkingBudget[component][index].baseCost) *
          Number(newState.eventWorkingBudget[component][index].quantity) *
          Number(newState.eventWorkingBudget[component][index].units) *
          (Number(newState.eventWorkingBudget.eventWorkingCommissionsAndTaxes.accommodationRoomTaxActual) / 100);

      newState.eventWorkingBudget[component][index].clientTax =
        Number(newState.eventWorkingBudget[component][index].clientSubtotal) *
        (Number(newState.eventWorkingBudget.eventWorkingCommissionsAndTaxes.accommodationRoomTaxActual) / 100);
    } else if (component === EEventWorkingBudgetComponent.eventWorkingBudgetFoodAndBeverageLines) {
      newState.eventWorkingBudget[component][index].amiCostTotal =
        Number(newState.eventWorkingBudget[component][index].baseCost) *
          Number(newState.eventWorkingBudget[component][index].quantity) *
          Number(newState.eventWorkingBudget[component][index].units) +
        Number(newState.eventWorkingBudget[component][index].baseCost) *
          Number(newState.eventWorkingBudget[component][index].quantity) *
          Number(newState.eventWorkingBudget[component][index].units) *
          (Number(newState.eventWorkingBudget.eventWorkingCommissionsAndTaxes.foodAndBeverageTaxActual) / 100);

      newState.eventWorkingBudget[component][index].clientTax =
        Number(newState.eventWorkingBudget[component][index].clientSubtotal) *
        (Number(newState.eventWorkingBudget.eventWorkingCommissionsAndTaxes.foodAndBeverageTaxActual) / 100);

      newState.eventWorkingBudget[component][index].clientSvcCharge =
        Number(newState.eventWorkingBudget[component][index].clientSubtotal) *
        (Number(newState.eventWorkingBudget.eventWorkingCommissionsAndTaxes.foodAndBeverageServiceChargeActual) / 100);
    } else {
      newState.eventWorkingBudget[component][index].amiCostTotal =
        Number(newState.eventWorkingBudget[component][index].baseCost) *
        Number(newState.eventWorkingBudget[component][index].quantity) *
        Number(newState.eventWorkingBudget[component][index].units);

      newState.eventWorkingBudget[component][index].clientTax = 0;
      newState.eventWorkingBudget[component][index].clientSvcCharge = 0;
    }

    newState.eventWorkingBudget[component][index].clientCostTotal =
      Number(newState.eventWorkingBudget[component][index].clientSubtotal) +
      Number(newState.eventWorkingBudget[component][index].clientSvcCharge) +
      Number(newState.eventWorkingBudget[component][index].clientTax);
    newState.eventWorkingBudget[component][index].totalProfit =
      Number(newState.eventWorkingBudget[component][index].clientCostTotal) -
      Number(newState.eventWorkingBudget[component][index].amiCostTotal);
    newState.eventWorkingBudget[component][index].variance =
      Number(newState.eventWorkingBudget[component][index].totalProfit) -
      Number(newState.eventWorkingBudget[component][index].profitGoal);
    return newState;
  },
  DELETE_LINE_OF_WORKING_BUDGET_BY_KEY: (state: State, action: DeleteLineOfWorkingBudgetByKey): State => {
    const { indexChangeOrder, indexLine } = action.payload;
    const newState = { ...state };
    newState.eventWorkingBudget.eventChangeOrders[indexChangeOrder].eventChangeOrderLines.splice(indexLine, 1);
    return newState;
  },
  UPDATE_CHANGE_ORDER_COMPONENT_BY_KEY: (state: State, action: UdpateWorkingBudgetComponentByKey): State => {
    const newState = { ...state };
    const { component, index, attribute, value, key } = action.payload;
    if (!component || !attribute) return newState;
    if (attribute?.includes('profitMarkupType')) {
      newState.eventWorkingBudget.eventChangeOrders[
        key || newState.eventWorkingBudget.eventChangeOrders.length - 1
      ].eventChangeOrderLines[index][attribute] =
        newState.eventWorkingBudget.eventChangeOrders[key || newState.eventWorkingBudget.eventChangeOrders.length - 1]
          .eventChangeOrderLines[index][attribute] === EProfitMarkupType.DOLLAR
          ? EProfitMarkupType.PERCENTAJE
          : EProfitMarkupType.DOLLAR;
      return newState;
    }
    if (attribute === 'pop') {
      newState.eventWorkingBudget.eventChangeOrders.pop();
      return newState;
    }
    if (attribute === 'add') {
      newState.eventWorkingBudget.eventChangeOrders = [...(newState.eventWorkingBudget.eventChangeOrders || []), value];
      return newState;
    }
    if (attribute === 'newLine') {
      newState.eventWorkingBudget.eventChangeOrders[
        key || newState.eventWorkingBudget.eventChangeOrders.length - 1
      ].eventChangeOrderLines = [
        ...newState.eventWorkingBudget.eventChangeOrders[
          key || newState.eventWorkingBudget.eventChangeOrders.length - 1
        ].eventChangeOrderLines,
        value
      ];
      return newState;
    }
    if (key && key === 'component') {
      newState.eventWorkingBudget[component][index][attribute] = value;
      return newState;
    }
    newState.eventWorkingBudget.eventChangeOrders[
      typeof key === 'number' ? key : newState.eventWorkingBudget.eventChangeOrders.length - 1
    ].eventChangeOrderLines[index][attribute] = value;
    return newState;
  },
  DELETE_CHANGE_ORDER_COMPONENT_BY_INDEX: (state: State, action: DeleteChangeOrderComponentByKey): State => {
    const { index } = action.payload;
    const newState = { ...state };
    newState.eventWorkingBudget.eventChangeOrders.splice(index, 1);
    return newState;
  },
  UPDATE_CHANGE_ORDER_BY_INDEX_FINAL_PRICE: (state: State, action: UdpateChangeOrderByIndexFinalPrice): State => {
    const newState = { ...state };
    const { indexLine, indexChangeOrder, component } = action.payload;
    if (
      indexLine === undefined ||
      indexLine === null ||
      indexChangeOrder === undefined ||
      indexChangeOrder === null ||
      !component
    ) {
      return newState;
    }

    const isMeetingFeeAndIsAMIExpense = (): boolean =>
      newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine]?.componentType
        ?.toLowerCase()
        .includes('management') &&
      newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine]?.descriptor
        ?.toLowerCase()
        .includes('ami');

    if (
      newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].profitMarkupType ===
      EProfitMarkupType.DOLLAR
    ) {
      newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[
        indexLine
      ].clientCostEach = isMeetingFeeAndIsAMIExpense()
        ? 0
        : Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].baseCost) +
          Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].markup);
    } else {
      newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[
        indexLine
      ].clientCostEach = isMeetingFeeAndIsAMIExpense()
        ? 0
        : Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].baseCost) +
          Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].markup) *
            (Number(
              newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].baseCost
            ) /
              100);
    }

    newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].clientSubtotal =
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].clientCostEach) *
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].quantity) *
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].units);

    newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].amiCostTotal =
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].baseCost) *
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].quantity) *
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].units);

    newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].clientCostTotal =
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].clientSubtotal) +
      Number(
        newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].clientSvcCharge
      ) +
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].clientTax);
    newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].totalProfit =
      Number(
        newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].clientCostTotal
      ) -
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].amiCostTotal);
    newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].variance =
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].totalProfit) -
      Number(newState.eventWorkingBudget[component][indexChangeOrder].eventChangeOrderLines[indexLine].profitGoal);
    return newState;
  },
  UPDATE_CHANGE_ORDER_LINES_BY_INDEX_FINAL_PRICE: (state: State, action: UdpateChangeOrderByIndexFinalPrice): State => {
    const newState = { ...state };
    const { indexChangeOrder, component } = action.payload;
    if (indexChangeOrder === undefined || indexChangeOrder === null || !component) {
      return newState;
    }
    if (newState?.eventWorkingBudget?.eventChangeOrders?.[indexChangeOrder]?.eventChangeOrderLines?.length) {
      newState?.eventWorkingBudget?.eventChangeOrders[indexChangeOrder].eventChangeOrderLines.forEach((line) => {
        if (line.componentType.toLowerCase().includes('accommodations')) {
          line.clientTax =
            (Number(line.clientSubtotal) *
              newState?.eventWorkingBudget?.eventChangeOrders[indexChangeOrder].accommodationRoomTax) /
            100;
        }
        if (line.componentType.toLowerCase().includes('f&b')) {
          line.clientTax =
            (Number(line.clientSubtotal) *
              newState?.eventWorkingBudget?.eventChangeOrders[indexChangeOrder].foodAndBeverageTax) /
            100;
          line.clientSvcCharge =
            (Number(line.clientSubtotal) *
              newState?.eventWorkingBudget?.eventChangeOrders[indexChangeOrder].foodAndBeverageServiceCharge) /
            100;
        }
      });
    }
    return newState;
  },
  TOGGLE_MARKUP_TYPE_BY_KEY: (state: State, action: ToggleMarkupTypeByKey): State => {
    const newState = { ...state };
    const { component, key, index } = action.payload;
    if (index !== null) {
      newState.eventBudget[component][key][index] = {
        ...state.eventBudget[component][key][index],
        profitMarkupType:
          state.eventBudget[component][key][index].profitMarkupType === EProfitMarkupType.DOLLAR
            ? EProfitMarkupType.PERCENTAJE
            : EProfitMarkupType.DOLLAR
      };
    } else {
      newState.eventBudget[component][key] = {
        ...state.eventBudget[component][key],
        profitMarkupType:
          state.eventBudget[component][key].profitMarkupType === EProfitMarkupType.DOLLAR
            ? EProfitMarkupType.PERCENTAJE
            : EProfitMarkupType.DOLLAR
      };
    }
    return newState;
  },
  TOGGLE_MARKUP_TYPE_MANAGEMENT_FEE: (state: State): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetManagementFee.managementFeeType =
      state.eventBudget.eventBudgetManagementFee.managementFeeType === EProfitMarkupType.DOLLAR
        ? EProfitMarkupType.PERCENTAJE
        : EProfitMarkupType.DOLLAR;
    return newState;
  },
  TOGGLE_MARKUP_TYPE_MANAGEMENT_FEE2: (state: State): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetManagementFee.managementFeeType =
      state.eventBudget.eventBudgetManagementFee.managementFeeType === EProfitMarkupType.DOLLAR
        ? EProfitMarkupType.PERCENTAJE
        : EProfitMarkupType.DOLLAR;
    return newState;
  },
  DELETE_MANAGEMENT_FEE: (state: State, action: DeleteManagementFee): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetManagementFee.managementFees.splice(action.payload.index, 1);
    return newState;
  },
  UPDATED_MANAGEMENT_FEE_FINAL_PRICE: (state: State, action: UpdateManagementFeeFinalPrice): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetManagementFee.managementFees[action.payload.index].total =
      Number(newState.eventBudget.eventBudgetManagementFee.managementFees[action.payload.index].perItemFee) *
      Number(newState.eventBudget.eventBudgetManagementFee.managementFees[action.payload.index].quantity);
    return newState;
  },
  UPDATED_MANAGEMENT_FEE_FINAL_PRICE2: (state: State): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetManagementFee.total =
      Number(newState.eventBudget.eventBudgetManagementFee.amiExpenses) +
      Number(newState.eventBudget.eventBudgetManagementFee.managementFee);
    return newState;
  },
  UPDATE_MANAGEMENT_FEE: (state: State, action: UpdateManagementFee): State => {
    const newState = { ...state };
    newState.eventBudget.eventBudgetManagementFee.managementFees[action.payload.index] = {
      ...state.eventBudget.eventBudgetManagementFee.managementFees[action.payload.index],
      [action.payload.key]: action.payload.value
    };
    return newState;
  },
  ADD_MANAGEMENT_FEE: (state: State): State => ({
    ...state,
    eventBudget: {
      ...state.eventBudget,
      eventBudgetManagementFee: {
        ...state.eventBudget.eventBudgetManagementFee,
        managementFees: [...state.eventBudget.eventBudgetManagementFee.managementFees, new ManagementFee()]
      }
    }
  })
};

const reducer = (state: State, action: Action): State =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export enum EFinalPriceByComponentName {
  eventBudgetRoomTypes = 'UPDATED_ROOM_TYPE_FINAL_PRICE',
  eventBudgetContractLabor = 'UPDATED_CONTRACT_LABOR_FINAL_PRICE',
  eventBudgetShippingAndPostage = 'UPDATED_SHIPPING_AND_POSTAGE_FINAL_PRICE',
  eventBudgetCreativeDevelopment = 'UPDATED_CREATIVE_DEVELOPMENT_FINAL_PRICE',
  eventBudgetSupportCollateral = 'UPDATED_SUPPORT_COLLATERAL_FINAL_PRICE',
  eventBudgetSignane = 'UPDATED_SIGNAGE_FINAL_PRICE',
  eventBudgetHonoraria = 'UPDATED_HONORARIA_TYPE_FINAL_PRICE',
  eventBudgetDisbursements = 'UPDATE_DISBURSEMENTS_FINAL_PRICE',
  eventBudgetVenue = 'UPDATED_VENUE_FINAL_PRICE',
  eventBudgetAdditionalCosts = 'UPDATED_ADDITIONAL_COSTS_FINAL_PRICE',
  eventBudgetDirectMarketing = 'UPDATED_DIRECT_MARKETING_FINAL_PRICE',
  eventBudgetAudienceGeneration = 'UPDATED_AUDIENCE_GENERATION_FINAL_PRICE',
  eventBudgetOtherMarketing = 'UPDATED_OTHER_MARKETING_FINAL_PRICE',
  eventBudgetTransportationGround = 'UPDATE_TRANSPORTATION_GROUND_FINAL_PRICE',
  eventBudgetTransportationAir = 'UPDATE_TRANSPORTATION_AIR_FINAL_PRICE',
  eventBudgetTransportation = 'UPDATE_TRANSPORTATION_FINAL_PRICE',
  eventBudgetAudioVisual = 'UPDATED_AUDIO_VISUAL_FINAL_PRICE',
  eventBudgetFoodAndBeverage = 'UPDATED_FOOD_AND_BEVERAGE_FINAL_PRICE',
  eventBudgetAmiInternalCost = 'UPDATED_AMI_INTERNAL_COST_FINAL_PRICE',
  eventBudgetManagementFee = 'UPDATED_MANAGEMENT_FEE_FINAL_PRICE',
  eventBudgetManagementFee2 = 'UPDATED_MANAGEMENT_FEE_FINAL_PRICE2'
}

export enum EFinalPriceByWorkingBudgetComponentName {
  eventWorkingBudgetFinalPrice = 'UPDATE_WORKING_BUDGET_BY_INDEX_FINAL_PRICE'
}

export enum EFinalPriceByChangeOrder {
  eventChangeOrderFinalPrice = 'UPDATE_CHANGE_ORDER_BY_INDEX_FINAL_PRICE',
  eventChangeOrderLinesTax = 'UPDATE_CHANGE_ORDER_LINES_BY_INDEX_FINAL_PRICE'
}

export default reducer;

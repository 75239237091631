import { FC } from 'react';
import 'react-phone-input-2/lib/material.css';
import PhoneInput2 from 'react-phone-input-2';

const customContainerStyle = {
  flexDirection: 'column' as 'column',
  position: 'relative' as 'relative',
  minWidth: 0,
  padding: 0,
  margin: 0,
  border: 0,
  verticalAlign: 'top',
  marginTop: '16px',
  marginBottom: '8px',
  width: '100%',
  backgroundColor: 'transparent'
};

const inputStyles = {
  width: '100%',
  borderRadius: 16,
  backgroundColor: 'transparent'
};

interface IPhoneInputProps {
  handleBlur: any;
  value: any;
  inputProps: {
    name: string;
    type: string;
    required: boolean;
  };
  handleOnChange: any;
  containerStyle?: React.CSSProperties;
  label?: string;
}

const PhoneInput: FC<IPhoneInputProps> = ({
  handleBlur,
  inputProps,
  value,
  handleOnChange,
  containerStyle,
  label = 'Phone Number'
}) => (
  <PhoneInput2
    inputProps={inputProps}
    onBlur={handleBlur}
    onChange={handleOnChange}
    value={value}
    country="us"
    placeholder={label}
    specialLabel={label}
    inputStyle={inputStyles}
    containerStyle={{
      ...customContainerStyle,
      ...containerStyle
    }}
  />
);
export default PhoneInput;

// eslint-disable-next-line import/no-mutable-exports
const BASE_URL: string = process.env.REACT_APP_API_URL;
console.log('NODE_ENV:', process.env.NODE_ENV);
console.log('ENV:', process.env.REACT_APP_ENV);
console.log('CID:', process.env.REACT_APP_CLIENT_ID);

export { BASE_URL };
export const EVENTS_URL = `${BASE_URL}/api/v1/events`;
export const EVENTS_INACTIVE_URL = `${BASE_URL}/api/v1/events/inactive`;
export const EVENTS_BUDGET_URL = `${BASE_URL}/api/v1/eventbudgets`;
export const EVENTS_BUDGET_BY_EVENT_URL = `${EVENTS_BUDGET_URL}/byevent`;
export const EVENTS_SCOPES = `${BASE_URL}/api/v1/events/getscopes`;
export const EVENTS_MEETING_TYPE = `${BASE_URL}/api/v1/events/getmeetingtypes`;
export const EVENTS_MEETING_SCOPE = `${BASE_URL}/api/v1/events/meetingscopes`;
export const EVENTS_URL_BY_STATUS = `${BASE_URL}/api/v1/events/getbystatus`;
export const EVENT_GET_REGIONS = `${EVENTS_URL}/getregions`;
export const EVENT_STAFFS = `${BASE_URL}/api/v1/eventstaffs`;
export const EVENT_STAFFS_ALL = `${BASE_URL}/api/v1/eventstaffs/all`;
export const CHILD_EVENTS_URL = `${BASE_URL}/api/v1/events/child`;
export const QUICKSIGHT_EMBEDDED_URL = `${BASE_URL}/api/v1/quicksight/report/operationalandfinancial`;
export const REPORTS_CLIENTS = `${BASE_URL}/api/v1/clients`;
export const REPORTS_CLIENTS_ALL = `${BASE_URL}/api/v1/clients/report`;
export const USER_ROLES = `${BASE_URL}/api/v1/roles`;
export const STORAGE_UPLOAD_URL = `${BASE_URL}/api/v1/s3/profilepicture/upload`;
export const STORAGE_LOAD_URL = `${BASE_URL}/api/v1/s3/profilepicture/load`;
export const ATTACHMENT_STORAGE_UPLOAD_URL = `${BASE_URL}/api/v1/s3/attachmentbudgetfile/upload`;
export const ATTACHMENT_STORAGE_LOAD_URL = `${BASE_URL}/api/v1/s3/attachmentbudgetfile/load`;
export const COGNITO_USER_URL = `${BASE_URL}/api/v1/cognitousers`;
export const COGNITO_USER_URL_BY_EMAIL = `${BASE_URL}/api/v1/cognitousers/byemail`;
export const COGNITO_USER_URL_RESET_PASSWORD = `${COGNITO_USER_URL}/resetpassword`;
export const COGNITO_USER_URL_FORGOT_PASSWORD = `${COGNITO_USER_URL}/forgotpassword`;
export const COGNITO_USER_ACTIVE_URL = `${COGNITO_USER_URL}/activate`;
export const COGNITO_USER_FULL_DELETE_URL = `${COGNITO_USER_URL}/fulldelete`;
export const MONDAY_BACKEND_ENDPOINT = `${BASE_URL}/api/v1/monday/boards/item`;
export const BUDGET_TEMPLATE_URL = `${BASE_URL}/api/v1/budgettemplate`;
export const BUDGET_TEMPLATE_COMPONENTS_URL = `${BUDGET_TEMPLATE_URL}/getcomponenttype`;
export const BUDGET_TEMPLATE_CLONE_URL = `${BUDGET_TEMPLATE_URL}/clonetemplate`;
export const BUDGET_TEMPLATE_ARCHIVE_URL = `${BUDGET_TEMPLATE_URL}/archive/`;
export const EVENT_BUDGET_URL = `${BASE_URL}/api/v1/eventbudgets`;
export const EVENT_BUDGET_URL_CONTRACT_LABOR_TYPES = `${BASE_URL}/api/v1/eventbudgets/getcontractlabortypes`;
export const EVENT_BUDGET_URL_FOOD_TYPES = `${BASE_URL}/api/v1/eventbudgets/getfoodtypes`;
export const EVENT_BUDGET_URL_SHIPPING_AND_POSTAGE_TYPES = `${BASE_URL}/api/v1/eventbudgets/getshippingandpostagetypes`;
export const EVENT_BUDGET_URL_CREATIVE_DEVELOPMENT_TYPES = `${BASE_URL}/api/v1/eventbudgets/getcreativedevelopmenttypes`;
export const EVENT_BUDGET_URL_SUPPORT_COLLATERALS_TYPES = `${BASE_URL}/api/v1/eventbudgets/getsupportcollateraltypes`;
export const EVENT_BUDGET_URL_SIGNAGE_TYPES = `${BASE_URL}/api/v1/eventbudgets/getsignagetypes`;
export const EVENT_BUDGET_URL_AUDIO_VISUAL_TYPES = `${BASE_URL}/api/v1/eventbudgets/getaudiovisualtypes`;
export const EVENT_BUDGET_URL_ADDITIONAL_COSTS_TYPES = `${BASE_URL}/api/v1/eventbudgets/getadditionalcosttypes`;
export const EVENT_BUDGET_URL_DIRECT_MARKETING_TYPES = `${BASE_URL}/api/v1/eventbudgets/getdirectmarketingtypes`;
export const EVENT_BUDGET_URL_ACCOMMODATION_ROOM_TYPE_TYPES = `${BASE_URL}/api/v1/eventbudgets/getaccommodationroomtypes`;
export const EVENT_BUDGET_URL_AUDIENCE_GENERATION_TYPES = `${BASE_URL}/api/v1/eventbudgets/getaudiencegenerationtypes`;
export const EVENT_BUDGET_URL_OTHER_COST_TYPES = `${BASE_URL}/api/v1/eventbudgets/getothercosttypes`;
export const EVENT_SIGNED_DOCS_URL = `${BASE_URL}/api/v1/eventsigneddocuments`;
export const EVENT_SIGNED_DOCS_BY_EVENT_URL = `${EVENT_SIGNED_DOCS_URL}/byevent`;
export const EVENT_SEND_SIGNED_DOCS_BY_EVENT_URL = `${EVENT_SIGNED_DOCS_URL}/sendemail`;
export const JOBS_URL = `${BASE_URL}/api/v1/jobs`;
export const JOBS_GET_INTAKE_FORMS_URL = `${JOBS_URL}/getintakeforms`;
export const EVENTS_VIRTUAL_COMPONENTS = `${BASE_URL}/api/v1/events/getvirtualcomponents`;
export const EVENTS_HYBRID_COMPONENTS = `${BASE_URL}/api/v1/events/gethybridcomponents`;
export const EVENTS_GET_NUMBER_OF_ATTENDEES = `${BASE_URL}/api/v1/events/getnumberofattendees`;
export const EVENTS_GET_INCOME_TYPES = `${BASE_URL}/api/v1/events/getincometypes`;
export const EVENTS_GET_RECONCILIATION_ON_DUE = `${BASE_URL}/api/v1/events/getreconciliationdue`;
export const EVENTS_GET_PAYMENT_TYPES = `${BASE_URL}/api/v1/events/getpaymenttypes`;
export const AUTHENTICATION_URL = `${BASE_URL}/api/v1/token`;
export const EVENTS_GET_STATUSES = `${BASE_URL}/api/v1/events/getstatuses`;
export const EVENT_SIMPLE_RECON_URL = `${BASE_URL}/api/v1/eventsimplereconciliations`;
export const EVENT_SIMPLE_RECON_BY_ID = `${EVENT_SIMPLE_RECON_URL}/byevent`;
export const EVENT_WORKING_BUDGET_URL = `${BASE_URL}/api/v1/eventworkingbudgets`;
export const EVENT_WORKING_BUDGET_BY_ID = `${EVENT_WORKING_BUDGET_URL}/byevent`;
export const EVENT_WORKING_CHANGE_ORDER = `${BASE_URL}/api/v1/eventchangeorders`;
export const EVENT_WORKING_CHANGE_ORDER_DELETE = `${EVENT_WORKING_CHANGE_ORDER}/delete`;
export const EVENT_WORKING_CHANGE_ORDER_COMPONENT_TYPES = `${EVENT_WORKING_CHANGE_ORDER}/getchangeordercomponenttypes`;
export const EVENT_PANDA_DOC = `${BASE_URL}/api/v1/pandadoc`;
export const EVENT_PANDA_DOC_DOWNLOAD_PDF = `${EVENT_PANDA_DOC}/download`;
export const EVENT_PANDA_DOC_SEND_EMAIL = `${EVENT_PANDA_DOC}/sendemail`;
export const EVENT_PANDA_DOC_DELETE = `${EVENT_PANDA_DOC}/delete`;
export const EVENT_RECONCILIATION_URL = `${BASE_URL}/api/v1/eventreconciliationbudgets`;
export const EVENT_RECONCILIATION_EXCEL_DOWNLOAD = `${EVENT_RECONCILIATION_URL}/exceldownload`;
export const EVENT_RECONCILIATION_BY_ID = `${EVENT_RECONCILIATION_URL}/byevent`;
export const EVENT_RECONCILIATION_DOWNLOAD_PDF = `${EVENT_RECONCILIATION_URL}/download`;
export const CLIENTS_URL = `${BASE_URL}/api/v1/clients`;
export const CLIENTS_TEMPLATES = `${CLIENTS_URL}/gettemplates`;
export const CLIENTS_ALL_URL = `${CLIENTS_URL}/getall`;
export const CLIENTS_INACTIVE_URL = `${CLIENTS_URL}/inactive/`;
export const ATTACHMENT_FILES_URL = `${BASE_URL}/api/v1/attachmentbudgetfiles`;
export const LOOKUPS_INDUSTRIES = `${BASE_URL}/api/v1/lookups/industries`;
export const LOOKUPS_STATES = `${BASE_URL}/api/v1/lookups/states`;
export const LOOKUPS_COUNTRIES = `${BASE_URL}/api/v1/lookups/countries`;
